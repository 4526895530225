import React from "react"

const Project = ({ props }) => (
  <>
      <div className={`bar bar-project`}>
        <span style={{paddingLeft: `5px`}}>Projects</span>
      </div>
      <div className={`project`}>
        <ul className={`auto-grid`}>
          {props.project.map( (data, index) => {
              return (
                <>
                    <li key={`${data.company.name}_project_${index}`}
                        style={{padding: `10px`, paddingBottom: `10px`}}>
                      <div style={{float: `left`, paddingBottom: `35px`}} >
                        <div className={`capitalize`}>
                          {data.role.title}
                        </div>
                        <div className={`company-history capitalize`}>
                          {data.company.name} {` | months: `} {data.date.months}
                        </div>
                        <div style={{borderTop: `solid black 2px`, paddingTop: `10px`}}>
                          {data.role.description}
                        </div>
                      </div>
                    </li>
                </>
              )
          })}
      </ul>
    </div>
  </>
)


export default Project
