import React from "react"

const Toolbox = ({props}) => (
  <>
  <div className={`bar bar-toolbox`}>
    <span style={{paddingLeft: `5px`}}>Toolbox</span>
  </div>
  <div className={`toolbox`}>
    <div className={`toolbox-item`}>
      <ul className={`toolbox-grid`}>
        {props.toolbox.map((data, index) => {
          return (
            <>
            <li key={`toolbox_section_${index}`}
                className={`capitalize`}
                style={{paddingTop: `15px`}}>
              {data.section.name}
            </li>
            <li>
              <div>
                <ul>
                  {data.tool.map( (tool, idx) =>
                    <li key={`toolbox_tool_${idx}`}
                        className={`capitalize`}>{tool}</li>
                  )}
                </ul>
              </div>
            </li>
            </>
          )
        })}
      </ul>
    </div>
  </div>
  </>
)

export default Toolbox
