import React from "react"
import Image from "./image"

const Header = ({ props, image }) => (
    <>
    <div className={`bar bar-heading`}></div>
    <div className={`heading`}>
      <ul className={`heading-grid`}>
        <li>
          <div className={`profile`}>
            <Image />
            <div style={{paddingTop: `5px`, letterSpacing: `0.057em`}}>
              <span className={`contact-name capitalize`}
                    style={{letterSpacing: `0.057em`}}>
                {props.name.split(" ")[0]}
              </span>
            </div>
            <div style={{letterSpacing: `-0.034em`}}>
              <span className={`contact-name capitalize`}
                    style={{letterSpacing: `-0.034em`}}>
                {props.name.split(" ")[1]}
              </span>
            </div>
            <div className={`contact`} style={{paddingLeft: '5px'}}>
              <span style={{}}>{props.contact.phone} {` | `} {props.contact.email}</span>
            </div>
          </div>
        </li>
        <li>
          <div className={`introduction`}>
            <div style={{padding: '5px'}}>
              <p>{props.brief}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    </>
)

export default Header
