import React from "react"

import SEO from "../components/seo"
import YAMLData from "../data/resume.yaml"
import Layout from "../components/layout"
import ResumeLayout from "../components/resume/layout"
import Header from "../components/resume/header"
import Experience from "../components/resume/experience"
import Project from "../components/resume/project"
import Toolbox from "../components/resume/toolbox"

const YAMLbuildtime = () => (
  <Layout>
    <SEO title="Resume" description={`
      Excels with opportunities to manage complex challenges of scale while using
      expertise in coding, algorithms, complexity analysis and large-scale system
      design. Being intellectually curious and a natural didact, problem seeking
      and openness are foundational attributes that promote his self-directed work
      ethic.`} />
    <ResumeLayout>
      <Header props={YAMLData} />
      <Experience props={YAMLData} />
      <Project props={YAMLData} />
      <Toolbox props={YAMLData} />
    </ResumeLayout>
  </Layout>
)

export default YAMLbuildtime
