import React from "react"

const Experience = ({ props }) => (
  <>
      <div className={`bar bar-experience`}>
        <span style={{paddingLeft: `5px`}}>Experience</span>
      </div>
      <div className={`experience`}>
        <ul className={`auto-grid`}>
          {props.experience.map( (data, index) => {
              return (
                <>
                    <li key={`${data.company.name}_${index}`}
                        style={{padding: `5px`, paddingBottom: `10px`}}>
                      <div style={{float: `left`, paddingBottom: `15px`}} >
                        <div className={`capitalize`}>
                          {data.role.title}
                        </div>
                        <div className={`company-history capitalize`}>
                          {data.company.name} {` | `} {data.role.date.start} {` - `} {data.role.date.end}
                        </div>
                        <div style={{borderTop: `solid black 2px`, paddingTop: `10px`}}>
                          {data.role.description}
                        </div>
                      </div>
                    </li>
                    <li key={`${data.company.name}_${index}_highlight_list`}>
                      <div className={`exp-list`}>
                        <ul style={{paddingLeft: `3.2em`}}>
                          {data.role.highlight.map( (highlight, idx) => 
                            <li key={`${data.company.name}_${index}_highlight_item_${idx}`}
                                style={{listStyleType: `"\\25AA"`}}>
                            <div style={{paddingLeft: `5px`}}>{highlight}</div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </li>
                </>
              )
          })}
      </ul>
    </div>
  </>
)


export default Experience
